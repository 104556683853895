// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-article-index-tsx": () => import("./../../../src/components/pages/article/index.tsx" /* webpackChunkName: "component---src-components-pages-article-index-tsx" */),
  "component---src-components-pages-cve-index-tsx": () => import("./../../../src/components/pages/cve/index.tsx" /* webpackChunkName: "component---src-components-pages-cve-index-tsx" */),
  "component---src-components-pages-industry-voice-index-tsx": () => import("./../../../src/components/pages/industry-voice/index.tsx" /* webpackChunkName: "component---src-components-pages-industry-voice-index-tsx" */),
  "component---src-components-pages-kenro-voice-index-tsx": () => import("./../../../src/components/pages/kenro-voice/index.tsx" /* webpackChunkName: "component---src-components-pages-kenro-voice-index-tsx" */),
  "component---src-components-pages-news-index-tsx": () => import("./../../../src/components/pages/news/index.tsx" /* webpackChunkName: "component---src-components-pages-news-index-tsx" */),
  "component---src-components-pages-plan-voice-index-tsx": () => import("./../../../src/components/pages/plan-voice/index.tsx" /* webpackChunkName: "component---src-components-pages-plan-voice-index-tsx" */),
  "component---src-components-pages-voice-index-tsx": () => import("./../../../src/components/pages/voice/index.tsx" /* webpackChunkName: "component---src-components-pages-voice-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-anti-harassment-index-tsx": () => import("./../../../src/pages/anti-harassment/index.tsx" /* webpackChunkName: "component---src-pages-anti-harassment-index-tsx" */),
  "component---src-pages-assessment-blockchain-index-tsx": () => import("./../../../src/pages/assessment/blockchain/index.tsx" /* webpackChunkName: "component---src-pages-assessment-blockchain-index-tsx" */),
  "component---src-pages-assessment-cloud-platform-index-tsx": () => import("./../../../src/pages/assessment/cloud_platform/index.tsx" /* webpackChunkName: "component---src-pages-assessment-cloud-platform-index-tsx" */),
  "component---src-pages-assessment-contact-index-tsx": () => import("./../../../src/pages/assessment/contact/index.tsx" /* webpackChunkName: "component---src-pages-assessment-contact-index-tsx" */),
  "component---src-pages-assessment-firebase-index-tsx": () => import("./../../../src/pages/assessment/firebase/index.tsx" /* webpackChunkName: "component---src-pages-assessment-firebase-index-tsx" */),
  "component---src-pages-assessment-graphql-index-tsx": () => import("./../../../src/pages/assessment/graphql/index.tsx" /* webpackChunkName: "component---src-pages-assessment-graphql-index-tsx" */),
  "component---src-pages-assessment-index-tsx": () => import("./../../../src/pages/assessment/index.tsx" /* webpackChunkName: "component---src-pages-assessment-index-tsx" */),
  "component---src-pages-assessment-iot-index-tsx": () => import("./../../../src/pages/assessment/iot/index.tsx" /* webpackChunkName: "component---src-pages-assessment-iot-index-tsx" */),
  "component---src-pages-assessment-lp-contact-index-tsx": () => import("./../../../src/pages/assessment/lp/contact/index.tsx" /* webpackChunkName: "component---src-pages-assessment-lp-contact-index-tsx" */),
  "component---src-pages-assessment-penetration-test-index-tsx": () => import("./../../../src/pages/assessment/penetration_test/index.tsx" /* webpackChunkName: "component---src-pages-assessment-penetration-test-index-tsx" */),
  "component---src-pages-assessment-plan-risk-focus-index-tsx": () => import("./../../../src/pages/assessment/plan/risk_focus/index.tsx" /* webpackChunkName: "component---src-pages-assessment-plan-risk-focus-index-tsx" */),
  "component---src-pages-assessment-plan-simulation-index-tsx": () => import("./../../../src/pages/assessment/plan_simulation/index.tsx" /* webpackChunkName: "component---src-pages-assessment-plan-simulation-index-tsx" */),
  "component---src-pages-assessment-plan-whitebox-tsx": () => import("./../../../src/pages/assessment/plan/whitebox.tsx" /* webpackChunkName: "component---src-pages-assessment-plan-whitebox-tsx" */),
  "component---src-pages-assessment-platform-index-tsx": () => import("./../../../src/pages/assessment/platform/index.tsx" /* webpackChunkName: "component---src-pages-assessment-platform-index-tsx" */),
  "component---src-pages-assessment-price-doc-index-tsx": () => import("./../../../src/pages/assessment/price_doc/index.tsx" /* webpackChunkName: "component---src-pages-assessment-price-doc-index-tsx" */),
  "component---src-pages-assessment-sample-report-index-tsx": () => import("./../../../src/pages/assessment/sample_report/index.tsx" /* webpackChunkName: "component---src-pages-assessment-sample-report-index-tsx" */),
  "component---src-pages-assessment-sp-app-index-tsx": () => import("./../../../src/pages/assessment/sp_app/index.tsx" /* webpackChunkName: "component---src-pages-assessment-sp-app-index-tsx" */),
  "component---src-pages-assessment-sp-game-index-tsx": () => import("./../../../src/pages/assessment/sp_game/index.tsx" /* webpackChunkName: "component---src-pages-assessment-sp-game-index-tsx" */),
  "component---src-pages-assessment-spa-index-tsx": () => import("./../../../src/pages/assessment/spa/index.tsx" /* webpackChunkName: "component---src-pages-assessment-spa-index-tsx" */),
  "component---src-pages-assessment-voice-index-tsx": () => import("./../../../src/pages/assessment/voice/index.tsx" /* webpackChunkName: "component---src-pages-assessment-voice-index-tsx" */),
  "component---src-pages-assessment-voice-recommendation-index-tsx": () => import("./../../../src/pages/assessment/voice/recommendation/index.tsx" /* webpackChunkName: "component---src-pages-assessment-voice-recommendation-index-tsx" */),
  "component---src-pages-assessment-web-app-index-tsx": () => import("./../../../src/pages/assessment/web_app/index.tsx" /* webpackChunkName: "component---src-pages-assessment-web-app-index-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-error-index-tsx": () => import("./../../../src/pages/contact/error/index.tsx" /* webpackChunkName: "component---src-pages-contact-error-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thankyou-index-tsx": () => import("./../../../src/pages/contact/thankyou/index.tsx" /* webpackChunkName: "component---src-pages-contact-thankyou-index-tsx" */),
  "component---src-pages-cookie-policy-index-tsx": () => import("./../../../src/pages/cookie-policy/index.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-tsx" */),
  "component---src-pages-cve-index-tsx": () => import("./../../../src/pages/cve/index.tsx" /* webpackChunkName: "component---src-pages-cve-index-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kenro-2023-new-grads-quiz-congratulations-index-tsx": () => import("./../../../src/pages/kenro/2023-new-grads-quiz/congratulations/index.tsx" /* webpackChunkName: "component---src-pages-kenro-2023-new-grads-quiz-congratulations-index-tsx" */),
  "component---src-pages-kenro-2023-new-grads-quiz-index-tsx": () => import("./../../../src/pages/kenro/2023-new-grads-quiz/index.tsx" /* webpackChunkName: "component---src-pages-kenro-2023-new-grads-quiz-index-tsx" */),
  "component---src-pages-kenro-cases-index-tsx": () => import("./../../../src/pages/kenro/cases/index.tsx" /* webpackChunkName: "component---src-pages-kenro-cases-index-tsx" */),
  "component---src-pages-kenro-contact-index-tsx": () => import("./../../../src/pages/kenro/contact/index.tsx" /* webpackChunkName: "component---src-pages-kenro-contact-index-tsx" */),
  "component---src-pages-kenro-cookie-policy-index-tsx": () => import("./../../../src/pages/kenro/cookie-policy/index.tsx" /* webpackChunkName: "component---src-pages-kenro-cookie-policy-index-tsx" */),
  "component---src-pages-kenro-download-service-index-tsx": () => import("./../../../src/pages/kenro/download/service/index.tsx" /* webpackChunkName: "component---src-pages-kenro-download-service-index-tsx" */),
  "component---src-pages-kenro-event-210713-index-tsx": () => import("./../../../src/pages/kenro/event/210713/index.tsx" /* webpackChunkName: "component---src-pages-kenro-event-210713-index-tsx" */),
  "component---src-pages-kenro-event-210818-index-tsx": () => import("./../../../src/pages/kenro/event/210818/index.tsx" /* webpackChunkName: "component---src-pages-kenro-event-210818-index-tsx" */),
  "component---src-pages-kenro-feature-index-tsx": () => import("./../../../src/pages/kenro/feature/index.tsx" /* webpackChunkName: "component---src-pages-kenro-feature-index-tsx" */),
  "component---src-pages-kenro-free-trial-terms-index-tsx": () => import("./../../../src/pages/kenro/free-trial-terms/index.tsx" /* webpackChunkName: "component---src-pages-kenro-free-trial-terms-index-tsx" */),
  "component---src-pages-kenro-index-tsx": () => import("./../../../src/pages/kenro/index.tsx" /* webpackChunkName: "component---src-pages-kenro-index-tsx" */),
  "component---src-pages-kenro-new-grads-training-index-tsx": () => import("./../../../src/pages/kenro/new-grads-training/index.tsx" /* webpackChunkName: "component---src-pages-kenro-new-grads-training-index-tsx" */),
  "component---src-pages-kenro-pricing-index-tsx": () => import("./../../../src/pages/kenro/pricing/index.tsx" /* webpackChunkName: "component---src-pages-kenro-pricing-index-tsx" */),
  "component---src-pages-kenro-terms-index-tsx": () => import("./../../../src/pages/kenro/terms/index.tsx" /* webpackChunkName: "component---src-pages-kenro-terms-index-tsx" */),
  "component---src-pages-kenro-thankyou-index-tsx": () => import("./../../../src/pages/kenro/thankyou/index.tsx" /* webpackChunkName: "component---src-pages-kenro-thankyou-index-tsx" */),
  "component---src-pages-kenro-thankyou-webinar-index-tsx": () => import("./../../../src/pages/kenro/thankyou_webinar/index.tsx" /* webpackChunkName: "component---src-pages-kenro-thankyou-webinar-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-reports-210401-pwn-2-own-index-tsx": () => import("./../../../src/pages/reports/210401_pwn2own/index.tsx" /* webpackChunkName: "component---src-pages-reports-210401-pwn-2-own-index-tsx" */)
}

