import React from "react";
import { Helmet } from "react-helmet";

export const wrapPageElement = ({ element, props }) => {
  const { location } = props;
  const canonicalUrl = `https://flatt.tech` + location.pathname;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="og:url" content={canonicalUrl} />
      </Helmet>
      {element}
    </>
  );
};
